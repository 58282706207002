@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

* {
  font-size: 10pt;
  font-family: "Verdana";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Verdana", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0.5em;
}

.transparent-backdrop {
  background: transparent;
  overflow-y: hidden;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

i.material-icons:before {
  display: none;
}

body {
  overflow: auto;
  height: 100%;
}

.modal-backdrop {
  background-color: #fff;
}

.blue {
  color: #fff;
  background: #2196f3;
}

.red {
  color: #fff;
  background: #f44336;
}

.green {
  color: #155724;
  background: #71cf87;
}

.yellow {
  color: #155724;
  background: rgb(255, 244, 89);
}

.testing {
  white-space: pre;
}
